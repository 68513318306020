


















































import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  data: function () {
    return {
      values: this.$store.state.values.coreValues,
      groups: this.$store.state.values.groups,
    };
  },
  components: {
    Timer,
    Footer,
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("ResetGroupSelection");
  },
  methods: {
    SelectGroup: function (
      selected: boolean,
      groupClass: string,
      index: number
    ) {
      if (!selected) {
        this.$store.dispatch("SelectGroup", { index: index });
        const dash = this.$refs["nav-dash"] as HTMLElement;
        dash.className = `navigating-dash ${groupClass}`;
        if (index === 0) {
          dash.style.left = "0";
        } else if (index === 4) {
          dash.style.left = "99%";
        } else {
          dash.style.left = index * 25 - 0.8 + "%";
        }
      } else {
        return;
      }
    },
    TogglePrimary(groupIndex: number, valIndex: number) {
      if (
        !this.$store.state.values.groups[groupIndex].values[valIndex].isPrimary
      ) {
        this.$store.dispatch("MakePrimaryValue", {
          groupIndex: groupIndex,
          index: valIndex,
        });
      }
    },
  },
});
